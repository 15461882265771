<template>
  <div>
    <validation-observer ref="videoInfoForm">
      <b-form>
        <b-card no-body>
          <b-card-header>
            <span v-if="videoDto.id > 0"> تفاصيل {{ videoDto.name }} </span>
            <span v-else>
              إضافة {{ videoDto.fileType ? "ملف" : " فيديو" }}
            </span>
          </b-card-header>
          <b-card-body class="pb-0">
            <validation-provider
              v-if="videoDto.id > 0"
              #default="{ errors }"
              name="اسم الفيديو"
              rules="required"
            >
              <b-form-group
                :label="videoDto.fileType ? 'اسم الملف' : 'اسم الفيديو'"
                label-for="videoName"
              >
                <b-form-input
                  id="videoName"
                  type="text"
                  v-model="videoDto.name"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="ترتيب الفيديو"
              rules="positive"
            >
              <b-form-group
                :label="videoDto.fileType ? 'ترتيب الملف' : 'ترتيب الفيديو'"
                label-for="videoRate"
              >
                <b-form-input
                  id="videoRate"
                  type="number"
                  v-model="videoDto.videoOrder"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-form-group
              :label="videoDto.fileType ? 'توصيف الملف' : 'توصيف الفيديو'"
              label-for="video-desciption"
            >
              <b-form-textarea
                id="video-desciption"
                rows="8"
                v-model="videoDto.description"
              />
            </b-form-group>
            <b-row class="align-items-center">
              <b-col cols="12" lg="9">
                <b-form-group
                  label="الأسئلة والإختبارات"
                  label-for="questions-task"
                >
                  <v-select
                    id="questions-task"
                    class="flex-grow-1"
                    :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                    label="name"
                    multiple
                    v-model="videoDto.questionBankIds"
                    :reduce="(option) => option.id"
                    :options="questionBankList"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="3" class="px-lg-0 text-center">
                <b-button
                  to="/question-bank/0"
                  variant="primary"
                  size="sm"
                  class="addQuestion w-sm-100"
                >
                  سؤال جديد
                  <unicon
                    width="18"
                    height="20"
                    name="plus"
                    fill="#fff"
                  ></unicon>
                </b-button>
              </b-col>
            </b-row>
            <b-form-group v-if="fileType" label="ملف pdf">
              <fileDragDrop
                @inValidFile="inValidVideo"
                @uploaded="uploadVideo"
                id="pdf-File"
                height="100px"
                type="pdf"
                title="أدرج ملف pdf هنا أو انقر للتحميل"
              />
              <small v-show="!!videoDto.videoPath" class="text-success"
                >تم تحميل ملف
              </small>
              <small
                class="text-danger"
                v-show="!videoDto.videoPath && !videoDto.file"
                >الرجاء اختيار ملف</small
              >
            </b-form-group>
            <b-form-group v-if="!fileType" label="غلاف الفيديو">
              <fileDragDrop
                @inValidFile="inValidThumbImage"
                @uploaded="uploadThumbImage"
                id="Thumb-File"
                height="100px"
                type="image"
                title="أدرج الصورة هنا أو انقر للتحميل"
              />
              <img
                v-if="!!videoDto.thumbPath"
                :src="videoDto.thumbPath"
                style="max-height: 320px; object-fit: fill"
                class="w-100 my-1"
              />
            </b-form-group>
            <b-form-group v-if="!fileType" label="الفيديو">
              <fileDragDrop
                @inValidFile="inValidVideo"
                @uploaded="uploadVideo"
                id="Video-File"
                height="100px"
                type="mp4"
                title="أدرج الفيديو هنا أو انقر للتحميل"
              />
              <!-- :src="!videoDto.fileDto ? "http://lms.elkood.com/" + videoDto.videoPath : videoDto.videoPath" -->
              <video
                v-if="!!videoDto.videoPath"
                :src="videoDto.videoPath"
                type="video/mp4"
                style="max-height: 320px"
                class="w-100 my-1"
                controls
              ></video>
              <small
                class="text-danger"
                v-show="!videoDto.videoPath && !videoDto.file"
                >الرجاء اختيار فيديو</small
              >
            </b-form-group>
          </b-card-body>
          <b-card-footer class="pt-0 border-0">
            <b-button variant="primary" class="mr-1" @click="submitNewVideo">
              {{ videoDto.id > 0 ? "تعديل" : "إضافة" }}
            </b-button>
            <b-button
              variant="outline-danger"
              class="mr-1"
              v-show="videoDto.id > 0"
              @click="SubmitRemoveVideo"
              >حذف</b-button
            >
            <b-button
              variant="outline-info"
              class="mr-1"
              v-show="fileType && videoDto.id > 0"
              :href="videoDto.videoPath"
              >عرض</b-button>
              <!-- @click="downloadFile(videoDto.videoPath)" -->
            <b-button variant="outline-primary" to="/lessons">عودة</b-button>
          </b-card-footer>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<style scoped>
.addQuestion {
  margin-top: 0.35rem;
}
</style>
<script>
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BButton,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import Swal from "sweetalert2";
import { required, positive } from "@validations";

export default {
  props: {
    id: String,
    videoId: String,
    fileType: Boolean,
  },
  components: {
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BButton,
    vSelect,
    fileDragDrop,
    BForm,
  },
  data: () => ({
    required,
    positive,
  }),
  created() {
    localize("ar");
  },
  beforeDestroy() {
    this.resetUnitId();
  },
  computed: {
    ...mapGetters({
      videoDto: "videoDto",
      questionBankList: "questionBankList",
      domainHost: "app/domainHost",
    }),
  },
  methods: {
    ...mapActions([
      "submitVideo",
      "deleteVideo",
      "resetUnitId",
      "downloadFile",
    ]),
    uploadVideo(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
        this.videoDto.videoPath = URL.createObjectURL(file);
        this.videoDto.file = file;
        if (!this.videoDto.fileDto) {
          this.videoDto.fileDto = {};
        }
      }).bind(this);
    },
    inValidVideo() {
      console.log("invalid file");
    },
    uploadThumbImage(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
        this.videoDto.thumbPath = reader.result;
        if (!this.videoDto.thumbDto) {
          this.videoDto.thumbDto = {};
        }
        Object.assign(this.videoDto.thumbDto, {
          base64: reader.result,
          contentType: file.type,
          fileName: file.name,
          length: file.size,
        });
      }).bind(this);
    },
    inValidThumbImage() {
      console.log("invalid Thumb file");
    },
    submitNewVideo() {
      this.$refs.videoInfoForm.validate().then((success) => {
        if (success && (!!this.videoDto.videoPath || !!this.videoDto.file)) {
          this.submitVideo(this.videoDto).then(() => {
            requestAnimationFrame(() => {
              this.$refs.videoInfoForm.reset();
            });
          });
        }
      });
    },
    SubmitRemoveVideo() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف الفيديو ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteVideo(this.videoId);
          }
        });
    },

  },
};

</script>

<style lang="scss">
.vs__deselect {
  margin-left: 8px !important;
}
</style>
